import { Tooltip } from "@material-ui/core"
import { mapObjPropsToLowercase } from "helpers/objPropsToLowercaseMapper"
import React, { useState } from "react"
import { sortAsc, sortDesc } from "utils/sort"

const AlternativeRatingsGrid = ({
  data,
  setData,
  dropdownSelection,
  objectives,
  alternativeName,
  objectivesAndMeasures,
}) => {
  const [sortBy, setSortBy] = useState("")
  const [sortDirection, setSortDirection] = useState("asc")

  const getSortIcon = prop => {
    let className = ""
    if (sortBy == prop) {
      className =
        sortDirection == "asc" ? "fas fa-chevron-down" : "fas fa-chevron-up"
    } else {
      className = "fas fa-chevron-down"
    }
    return (
      <i
        className={className}
        style={{ padding: "0px", marginLeft: "5px" }}
      ></i>
    )
  }

  const handleSort = prop => {
    let direction = ""
    if (sortBy == prop) {
      direction = sortDirection == "asc" ? "desc" : "asc"
    } else {
      direction = "asc"
      setSortBy(prop)
    }
    setSortDirection(direction)

    setData(
      direction == "asc"
        ? mapObjPropsToLowercase(sortAsc(data, prop))
        : mapObjPropsToLowercase(sortDesc(data, prop))
    )
  }

  return (
    <React.Fragment>
      <div
        style={{
          height: "65vh",
          marginBotton: "0px",
          overflowY: "auto",
          overflowX: "auto",
        }}
      >
        <table
          className="table table-bordered low-padding-table"
          style={{ backgroundColor: "white" }}
        >
          <thead>
            <tr>
              <th style={{ width: "5%" }}>ID</th>
              <th style={{ width: "15%" }}>{alternativeName}</th>
              <th>Version</th>
              {dropdownSelection.value == 0 &&
                objectives.map((o, i) => {
                  return <th key={i}>{o.objectiveName}</th>
                })}
              {dropdownSelection.value == -99 &&
                objectivesAndMeasures.map((o, i) => {
                  if (o.measureID != undefined) {
                    return <th key={i}>{o.measureName}</th>
                  }
                  return <th key={i}>{o.objectiveName}</th>
                })}
              {dropdownSelection.value > 0 &&
                objectivesAndMeasures
                  .filter(
                    x =>
                      x.measureID != undefined &&
                      x.objectiveID == dropdownSelection.value
                  )
                  .map((m, i) => {
                    return <th key={i}>{m.measureName}</th>
                  })}
              <th
                style={{ width: "10%", cursor: "pointer" }}
                onClick={() => handleSort("saaScore")}
              >
                Benefit Score {getSortIcon("saaScore")}
              </th>
              <th style={{ width: "10%" }}>Group Rating</th>

              <th 
                style={{ width: "10%", cursor: "pointer"  }}
                  onClick={() => handleSort("saaScoreNormal")}
                  >
                  Normalized Score {getSortIcon("saaScore")}
                  </th>
              <th
                style={{ width: "10%", cursor: "pointer" }}
                onClick={() => handleSort("costBenefit")}
              >
                Benefit / Cost Ratio {getSortIcon("costBenefit")}
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((a, idx) => (
              <tr key={idx}>
                <td>{a.alternativeNum}</td>
                <td className="one-line-elipsis" style={{ cursor: "pointer" }}>
                  <Tooltip title={<h6>{a.alternative}</h6>}>
                    <span>
                      <b>{a.alternative}</b>
                    </span>
                  </Tooltip>
                </td>
                <td>
                  <div
                    style={{ textAlign: "center" }}
                    dangerouslySetInnerHTML={{
                      __html: a.version,
                    }}
                  ></div>
                </td>
                {dropdownSelection.value == 0 &&
                  objectives.map((o, i) => {
                    return (
                      <td key={i}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              a[
                                o.objectiveName
                                  .toLowerCase()
                                  .replaceAll(",", "")
                                  .replaceAll('"', "")
                                  .replaceAll("'", "")
                              ],
                          }}
                        ></div>
                        {/* {
                      a[
                        o.objectiveName
                          .toLowerCase()
                          .replaceAll(",", "")
                          .replaceAll('"', "")
                          .replaceAll("'", "")
                      ]
                    } */}
                      </td>
                    )
                  })}
                {dropdownSelection.value == -99 &&
                  objectivesAndMeasures.map((o, i) => {
                    if (o.measureID != undefined) {
                      return (
                        <td key={i}>
                          <div
                            dangerouslySetInnerHTML={{
                              __html:
                                a[
                                  o.measureName
                                    .toLowerCase()
                                    .replaceAll(",", "")
                                    .replaceAll('"', "")
                                    .replaceAll("'", "")
                                ],
                            }}
                          ></div>
                          {/* {
                        a[
                          o.measureName
                            .toLowerCase()
                            .replaceAll(",", "")
                            .replaceAll('"', "")
                            .replaceAll("'", "")
                        ]
                      } */}
                        </td>
                      )
                    }
                    return (
                      <td key={i}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              a[
                                o.objectiveName
                                  .toLowerCase()
                                  .replaceAll(",", "")
                                  .replaceAll('"', "")
                                  .replaceAll("'", "")
                              ],
                          }}
                        ></div>
                        {/* {
                      a[
                        o.objectiveName
                          .toLowerCase()
                          .replaceAll(",", "")
                          .replaceAll('"', "")
                          .replaceAll("'", "")
                      ]
                    } */}
                      </td>
                    )
                  })}
                {dropdownSelection.value > 0 &&
                  objectivesAndMeasures
                    .filter(
                      x =>
                        x.measureID != undefined &&
                        x.objectiveID == dropdownSelection.value
                    )
                    .map((m, i) => {
                      return (
                        <td key={i}>
                          <div
                            dangerouslySetInnerHTML={{
                              __html:
                                a[
                                  m.measureName
                                    .toLowerCase()
                                    .replaceAll(",", "")
                                    .replaceAll('"', "")
                                    .replaceAll("'", "")
                                ],
                            }}
                          ></div>
                          {/* {
                        a[
                          m.measureName
                            .toLowerCase()
                            .replaceAll(",", "")
                            .replaceAll('"', "")
                            .replaceAll("'", "")
                        ]
                      } */}
                        </td>
                      )
                    })}
                <td>
                  <div dangerouslySetInnerHTML={{ __html: a.saaScore }}></div>
                </td>
                <td>
                  <div dangerouslySetInnerHTML={{ __html: a.saaRating }}></div>
                </td>
                <td>{parseFloat(a.saaScoreNormal).toFixed(2)}</td>
                <td>{parseFloat(a.costBenefit).toFixed(3)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  )
}

export default AlternativeRatingsGrid
